import * as React from "react";
import { Link } from "gatsby";

// styles
const pageStyles = {
  backgroundColor: "#1B3335",
  color: "#F2C2B3",
  padding: "96px",
  fontFamily: " Abril Fatface",
  height: "100vh",
};

const head = { fontSize: "15rem" };
const headingStyles = {
  marginTop: 0,
  marginBottom: 20,
  maxWidth: 320,
};

const h = {
  marginBottom: 80,
};

const link = {
  color: "#B66049",
  fontSize: "24px",
};

const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <title>Not found</title>
      <h1 style={head}>404</h1>
      <h1 style={headingStyles}>Oh No!</h1>
      <h2 style={h}>Sorry this page does not exist</h2>
      <Link style={link} to="/">
        Take me back
      </Link>
    </main>
  );
};

export default NotFoundPage;
